<template>
  <!-- begin:: Header -->
  <div>
    <div
        :style=" $store.state.auth.user.type === 0 ? 'left: 0;' : '' "
        id="kt_top_header"
        ref="kt_top_header"
        class="header"
        v-bind:class="headerClasses"
    >
      <div
          class="container-fluid d-flex align-items-center justify-content-between"
      >
        <div
            class="header-menu-wrapper header-menu-wrapper-left"
        >
          <h6 class="mb-0">Welcome {{ this.$store.state.auth.user.name }}!</h6>

        </div>

        <KTTopbar></KTTopbar>
      </div>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import {mapGetters} from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/header/Menu.vue";
import {bus} from "@/main";
export default {
  name: "KTHeader",
  components: {
    KTTopbar,
    KTMenu
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_top_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
        this.$refs["kt_header_menu"],
        this.$refs["kt_header_menu_wrapper"]
    );
  },
  methods:{
    shCCDialog() {
      bus.$emit('cCDialogOpen', true);
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
