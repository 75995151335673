<template>
  <div class="topbar-item">
    <div id="kt_quick_user_toggle">
      <div class="symbol" style="cursor: pointer">
        <img style="margin-top: 5px" class="symbol-label" :src="picture" alt=""/>
      </div>
    </div>

    <div
        id="kt_quick_user"
        ref="kt_quick_user"
        class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <!--      <div-->
      <!--        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"-->
      <!--      >-->
      <!--        <h3 class="font-weight-bold m-0">-->
      <!--          User Profile-->
      <!--          <small class="text-muted font-size-sm ml-2">12 messages</small>-->
      <!--        </h3>-->
      <!--        <a-->
      <!--          href="#"-->
      <!--          class="btn btn-xs btn-icon btn-light btn-hover-primary"-->
      <!--          id="kt_quick_user_close"-->
      <!--        >-->
      <!--          <i class="ki ki-close icon-xs text-muted"></i>-->
      <!--        </a>-->
      <!--      </div>-->
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
          class="offcanvas-content pr-5 mr-n5 scroll"
          style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="picture" alt=""/>
          </div>
          <div class="d-flex flex-column">
            <a
                href="#"
                class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ this.$store.state.auth.user.name }}
            </a>
            <!--            <div class="text-muted mt-1">Application Developer</div>-->
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-text text-muted text-hover-primary">
                    {{ this.$store.state.auth.user.email }}
                  </span>
                </span>
              </a>
            </div>
            <v-btn color="primary" dark class="btn btn-bold" @click="onLogout">
              Sign Out
              <v-icon dark x-small class="ml-1">fas fa-sign-out-alt</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <div class="navi-link">
            <div class="navi-text">
              <v-btn color="primary" dark class="btn btn-bold" style="width: 100%" @click="shCpDialog">
                Change Password
                <v-icon dark x-small class="ml-1">fas fa-lock</v-icon>
              </v-btn>
            </div>
          </div>
          <!--end:Item-->
        </div>
        <!--end::Header-->
        <!--end::Notifications-->
        <div class="separator separator-dashed mt-5"></div>

      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import {DO_LOGOUT} from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import {bus} from "@/main";
import {mapState} from "vuex";

export default {
  name: "KTQuickUser",
  data() {
    return {};
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
          .dispatch(DO_LOGOUT)
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    shCpDialog() {
      bus.$emit('cPDialogOpen', true);
    },
  },
  computed: {
    ...mapState({
      errors: state => state.users.errors
    }),
    picture() {
      return process.env.BASE_URL + "media/users/default.jpg";
    }
  }
};
</script>
